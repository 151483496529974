import styled, { css, CSSProp } from 'styled-components'

const Subhead = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  `}
`

export default Subhead
