import { useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'

import LinkIcon from '../../images/svg/link.svg'
import Icon from '../atoms/icon'

const ShareButton = styled.a`
  ${({ theme }): CSSProp => css`
    width: 3.8rem;
    height: 3.8rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.1rem solid ${theme.colours.primary};
    box-shadow: 0 0 0.1rem 0 ${theme.colours.primary} inset,
      0 0 0.1rem 0 ${theme.colours.primary};

    .hover-icon {
      display: none;
      width: 1rem;
    }

    &:hover {
      color: ${theme.colours.tertiary};
      border-color: ${theme.colours.tertiary};
      box-shadow: 0 0 0.1rem 0 ${theme.colours.tertiary} inset,
        0 0 0.1rem 0 ${theme.colours.tertiary};

      .icon {
        display: none;
      }

      .hover-icon {
        display: block;
      }
    }
  `}
`

type ShareIconType = {
  children: React.ReactNode
  type: 'facebook' | 'twitter' | 'linkedin'
}

const ShareIcon = (props: ShareIconType): JSX.Element => {
  const { children, type } = props
  const [location, setLocation] = useState('')
  const locationData = useLocation()

  useEffect(() => {
    setLocation(locationData.href)
  }, [locationData])

  let shareUrl

  switch (type) {
    case 'facebook':
      shareUrl = 'https://www.facebook.com/sharer/sharer.php?u='
      break

    case 'twitter':
      shareUrl = 'https://twitter.com/intent/tweet?url='
      break

    case 'linkedin':
      shareUrl = 'https://www.linkedin.com/sharing/share-offsite/?url='
      break
  }

  return (
    <ShareButton href={`${shareUrl}${encodeURI(location)}`} target="_blank">
      <Icon className="icon" size="medium">
        {children}
      </Icon>
      <Icon className="hover-icon" size="medium">
        <LinkIcon />
      </Icon>
    </ShareButton>
  )
}

ShareIcon.propTypes = {}

export default ShareIcon
