import styled, { css, CSSProp } from 'styled-components'

const Breadcrumbs = styled.div<{ margin?: boolean }>`
  ${({ theme, margin }): CSSProp => css`
    font-size: ${theme.font.fontSizeSmall};
    font-weight: ${theme.font.fontWeightLight};
    margin-top: ${margin ? '3.8rem' : 0};
    margin-bottom: 1.5rem;
    @media only screen and ${theme.breakpoints.toNormalScreen} {
      display: none;
    }
    a {
      text-decoration: none;
    }
    span {
      font-size: 0;
      vertical-align: middle;
      margin: 0 0.5rem 0 0.3rem;
      &:after {
        content: '';
        width: 17px;
        height: 1px;
        background-color: ${theme.colours.primary};
        display: inline-block;
      }
    }
    svg {
      transform: rotate(90deg);
      width: 1rem;
      vertical-align: middle;
      margin-right: 1rem;
      position: relative;
      top: -1px;
    }
  `}
`

export default Breadcrumbs
