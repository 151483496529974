import styled, { css, CSSProp } from 'styled-components'

const ArticleHeader = styled.div<{ hasImage: boolean; type?: string }>`
  ${({ theme, hasImage, type }): CSSProp => css`
    overflow: hidden;
    background-color: ${type === 'cafe'
      ? theme.colours.light
      : theme.colours.secondary};
    padding-top: ${hasImage ? 0 : '1rem'};
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      padding-top: 3.8rem;
      background-color: ${hasImage
        ? theme.colours.secondary
        : theme.colours.light};
    }
    .content {
      margin: 0 auto;
    }
    ${!hasImage &&
      css`
        .card {
          @media only screen and ${theme.breakpoints.fromNormalScreen} {
            display: none;
          }
        }
      `}
  `}
`
export default ArticleHeader
