import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import Facebook from '../../images/svg/facebook.svg'
import LinkedIn from '../../images/svg/linkedin.svg'
import Twitter from '../../images/svg/twitter.svg'
import ShareIcon from '../molecules/ShareIcon'

const StyledShare = styled.div`
  ${({ theme }): CSSProp => css`
    a {
      margin-bottom: 1.5rem;
    }
    @media only screen and ${theme.breakpoints.toNormalScreen} {
      a {
        margin-bottom: 0;
        display: inline-flex;
        + a {
          margin-left: 1.3rem;
        }
      }
    }
  `}
`

const Share = (): JSX.Element => (
  <StyledShare className="share">
    <ShareIcon type="facebook">
      <Facebook />
    </ShareIcon>
    <ShareIcon type="twitter">
      <Twitter />
    </ShareIcon>
    <ShareIcon type="linkedin">
      <LinkedIn />
    </ShareIcon>
  </StyledShare>
)

export default Share
